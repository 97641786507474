import { storyblokEditable } from '@storyblok/react';
import {Box, SystemProps} from "@storyofams/react-ui";

import {Button, Container, Heading, Text} from "~components";
import { DynamicSectionContainer } from '..';
import {useTranslations} from "~context";
import {getLinkProps} from "~lib";

type QuoteProps = {
  content: any;
  first?: boolean;
} & SystemProps;

export const Quote = ({ content, first, ...props }: QuoteProps) => {

  return (
    <DynamicSectionContainer
      bg={content?.bg} {...props} {...storyblokEditable(content)}
    >
      <Container
        mt={2}
        m={2}
        py={10}
      >
        <Heading
          as={first ? 'h1' : 'h2'}
          variant="h2"
          lineHeight="3rem"
          maxWidth="750px"
          color={content?.bg === 'primary900' ? 'white' : content?.text_color}
          fontWeight="bold"
          fontSize={2}
          mb={2}
        >
          {content?.quote}
        </Heading>

        <Text
          variant="l"
          color={content?.bg === 'primary900' ? 'white' : content?.text_color}
          fontWeight={100}
          mt={0}
          mb={5}
        >
          {content?.text}
        </Text>
  
        {typeof content?.cta_label === 'string' && content?.cta_label !== '' ?
        <Button
          variant={content?.bg === 'primary900' ? 'white' : 'link'}
          fontSize={[1.75]}
          ml={['auto', 'auto', 0]}
          width={'fit-content'}
          href={getLinkProps(content?.cta_link)}
        >
          <Box as="span" display='flex'>
            {content?.cta_label}
          </Box>
        </Button>
          : null }
      </Container>
    </DynamicSectionContainer>
  );
};
